import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BadgeCheckIcon, PlusIcon, QrcodeIcon, StarIcon, ThumbUpIcon, TrashIcon, XCircleIcon, XIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import { LinkIcon, PlusSmIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import SingleSelection from './singleSelection';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { versionRegister } from '../services/versionRegister';
import { userRegister } from '../services/userRegister';

class CreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            listings: [],
            user_enterprise: {},
            enterprises: [],
            form: {},
            version: {},
            access: {},
            tab: { name: "Questions", value: "questions" },
            questionsIndex: {
                "1": { id: 1, name: 'First', value: 0 },
                "2": { id: 2, name: 'Second', value: 1 },
                "3": { id: 3, name: 'Third', value: 2 },
                "4": { id: 4, name: 'Fourth', value: 3 },
                "5": { id: 5, name: 'Fifth', value: 4 },
                "6": { id: 6, name: 'Sixth', value: 5 },
                "7": { id: 7, name: 'Seventh', value: 6 },
                "8": { id: 8, name: 'Eighth', value: 7 },
                "9": { id: 9, name: 'Ninth', value: 8 },
                "10": { id: 10, name: 'Tenth', value: 9 }
            },
            lang_options: [
                { id: 1, name: 'English', value: "en" },
                { id: 2, name: 'Swedish', value: "sv" },
                { id: 3, name: 'Arabic', value: "ar" },
                { id: 4, name: 'German', value: "de" },
                { id: 5, name: 'Spanish', value: "es" },
                { id: 6, name: 'French', value: "fr" },
                { id: 7, name: 'Russian', value: "ru" },
                { id: 8, name: 'Dutch', value: "nl" },
                { id: 9, name: 'Portuguese', value: "pt" },
                { id: 10, name: 'Italian', value: "it" }
            ],
            themeSettings: {
                platform: {
                    themeBackgroundEffect: { id: 3, name: 'Mesh', value: "mesh" },
                    themeBackgroundFilter: { id: 1, name: 'None', value: "none" },
                    themePhotoFilter: { id: 1, name: 'Color', value: "color" },
                    themeIslandStyle: { id: 1, name: 'Bright', value: "bright" },
                    themeIslandBackground: { id: 1, name: 'Enabled', value: "enabled" },
                    themeAccent: { id: 1, name: 'Partial', value: "partial" }
                },
                dark_mode: {
                    themeBackgroundEffect: { id: 4, name: 'Blur', value: "blur" },
                    themeBackgroundFilter: { id: 3, name: 'Dark', value: "dark" },
                    themePhotoFilter: { id: 1, name: 'Color', value: "color" },
                    themeIslandStyle: { id: 2, name: 'Dark', value: "dark" },
                    themeIslandBackground: { id: 2, name: 'Disabled', value: "disabled" },
                    themeAccent: { id: 2, name: 'Full', value: "full" }
                },
                bw_mode: {
                    themeBackgroundEffect: { id: 4, name: 'Blur', value: "blur" },
                    themeBackgroundFilter: { id: 3, name: 'Dark', value: "dark" },
                    themePhotoFilter: { id: 2, name: 'B/w', value: "bw" },
                    themeIslandStyle: { id: 1, name: 'Bright', value: "bright" },
                    themeIslandBackground: { id: 2, name: 'Disabled', value: "disabled" },
                    themeAccent: { id: 2, name: 'Full', value: "full" }
                },
                proptree: {
                    themeBackgroundEffect: { id: 1, name: 'Solid', value: "solid" },
                    themeBackgroundFilter: { id: 2, name: 'Color', value: "color" },
                    themePhotoFilter: { id: 1, name: 'Color', value: "color" },
                    themeIslandStyle: { id: 1, name: 'Bright', value: "bright" },
                    themeIslandBackground: { id: 1, name: 'Enabled', value: "enabled" },
                    themeAccent: { id: 1, name: 'Partial', value: "partial" }
                }
            }
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    async componentWillReceiveProps(nextProps) {

        let user = userRegister.get();
        await this.promisedSetState({ user: user });

        let market = this.state.user.enterprise && this.state.user.enterprise.market && this.state.user.enterprise.market.value ? this.state.user.enterprise.market.value : "ae";
        let currency = this.state.user.enterprise && this.state.user.enterprise.baseCurrency && this.state.user.enterprise.baseCurrency.value ? this.state.user.enterprise.baseCurrency.value.toUpperCase() : "AED";

        if (!this.state.open && nextProps.open) {

            this.functions.getListings();
            this.state.listing = null;

            this.state.form = (nextProps.form ? nextProps.form : {
                name: "",
                language: this.renders.localize(market, "base_language", currency) ? this.state.lang_options.find(option => option.value === this.renders.localize(market, "base_language", currency)) : this.state.lang_options[0],
                type: { id: 1, name: '2FA -> Qualify', value: 'questions_last' },
                perks: this.state.user.enterprise && this.state.user.enterprise.managerUIcreateQuestions && this.state.user.enterprise.managerUIcreateQuestions.value == "disabled" ? { id: 2, name: 'Disabled', value: 'perks_disabled' } : { id: 2, name: 'Disabled', value: 'perks_disabled' },
                version: this.state.user.enterprise && this.state.user.enterprise.managerUIcreateQuestions && this.state.user.enterprise.managerUIcreateQuestions.value == "disabled" ? { id: 2, name: 'Disabled', value: 'no_questions' } : { id: 1, name: 'Enabled', value: 'questions' },
                questions: [{
                    open: true,
                    index: { id: 1, name: 'First', value: 0 },
                    id: Math.floor(Math.random() * 10000000000000),
                    question: "",
                    information: "",
                    options: [{ open: true, id: Math.floor(Math.random() * 10000000000000), title: "", qualification: false }]
                }],
                color: this.state.user.enterprise && this.state.user.enterprise.color && typeof this.state.user.enterprise.color == "string" ? this.state.user.enterprise.color.replace("#", "") : "667EEA",
                qrColor: "343338",
                theme: { id: 1, name: 'Platform', value: "platform" },
                themeBackgroundEffect: { id: 3, name: 'Mesh', value: "mesh" },
                themeBackgroundFilter: { id: 1, name: 'None', value: "none" },
                themePhotoFilter: { id: 1, name: 'Color', value: "color" },
                themeIslandStyle: { id: 1, name: 'Bright', value: "bright" },
                themeIslandBackground: { id: 1, name: 'Enabled', value: "enabled" },
                themeAccent: { id: 1, name: 'Partial', value: "partial" }
            });
            this.state.form.questions = this.state.form.questions.map((item) => {
                if (nextProps.form) {
                    item.open = false;
                }
                return item;
            });
        }

        this.setState({
            listing: this.state.listing,
            base_language: this.renders.localize(market, "base_language", currency),
            base_currency: this.renders.localize(market, "base_currency", currency),
            base_size_unit: this.renders.localize(market, "base_size_unit", currency),
            base_dial_code: this.renders.localize(market, "base_dial_code", currency),
            form: this.state.form,
            open: nextProps.open,
            user_enterprise: nextProps.enterprise
        })
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({ user: user });
        },
        createForm: async () => {
            try {
                await this.promisedSetState({ loading: true, error: false });
                let form = JSON.parse(JSON.stringify(this.state.form));
                let response = await this.calls.createForm(form);
                await this.promisedSetState({
                    loading: false,
                    form: response.data
                });
                /*
                this.props.toggle();
                if (this.props.onCreate) {
                    this.props.onCreate();
                }
                */
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        updateForm: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let form = JSON.parse(JSON.stringify(this.state.form));
                let response = await this.calls.updateForm(form);
                await this.promisedSetState({
                    loading: false,
                    form: response.data,
                    hide_preview: true
                });
                await this.promisedSetState({
                    hide_preview: false
                });
            } catch (error) {
                await this.promisedSetState({
                    error: error.body ? error.body.message : "Something went wrong",
                    loading: false
                });
            }
        },
        setThemeSettings: (theme) => {
            let isProptree = theme.value === 'proptree';

            this.setState((prevState) => ({
                form: {
                    ...prevState.form,
                    theme: theme,
                    themeBackgroundEffect: prevState.themeSettings[theme.value].themeBackgroundEffect,
                    themeBackgroundFilter: prevState.themeSettings[theme.value].themeBackgroundFilter,
                    themePhotoFilter: prevState.themeSettings[theme.value].themePhotoFilter,
                    themeIslandStyle: prevState.themeSettings[theme.value].themeIslandStyle,
                    themeIslandBackground: prevState.themeSettings[theme.value].themeIslandBackground,
                    themeAccent: prevState.themeSettings[theme.value].themeAccent,
                    // Add the color and qrColor properties to form if "proptree" is selected
                    ...(isProptree && {
                        color: "F9FAFB",
                        qrColor: this.state.user.enterprise && this.state.user.enterprise.color && typeof this.state.user.enterprise.color === "string" ?
                            this.state.user.enterprise.color.replace("#", "") : "667EEA"
                    })
                }
            }));
        },
        getListings: async () => {
            try {
                await this.promisedSetState({ loading_listings: true });
                let response = await this.calls.getListings();
                await this.promisedSetState({
                    listings: response.data.map((item) => {
                        if (this.state.user_enterprise && this.state.user_enterprise.parserType && this.state.user_enterprise.parserType.value.indexOf("project") !== -1) {
                            item.name = item.projectName + "  #" + item.reference;
                        } else {
                            item.name = item.name + "  #" + item.reference;
                        }
                        return item;
                    })
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_listings: false });
        }
    };

    renders = {
        localize: (market, type, currency) => {

            //AVAILABLE MARKETS
            //TODO: add new markets here
            //TODO: some translations are done directly in form front-end, be sure to add support for new platform markets and langs also in front-end

            console.log(market + " " + type + " " + currency);

            // Default values
            let currency_before = true;
            let base_language = "en";
            let base_currency = currency || "AED";
            let base_size_unit = "sqft";
            let base_dial_code = "971";

            // Market-specific overrides
            if (market === "ae" || market === "uae") {
                currency_before = false;
                base_language = "en";
                base_currency = currency || "AED";
                base_size_unit = "sqft";
                base_dial_code = "971";
            } else if (market === "se") {
                currency_before = false;
                base_language = "sv";
                base_currency = currency || "SEK";
                base_size_unit = "sqm";
                base_dial_code = "46";
            } else if (market === "gb") {
                currency_before = true;
                base_language = "en";
                base_currency = currency || "GBP";
                base_size_unit = "sqft";
                base_dial_code = "44";
            }

            switch (type) {
                case "base_language":
                    return base_language;
                case "base_currency":
                    return base_currency.toUpperCase();
                case "base_size_unit":
                    return base_size_unit;
                case "base_dial_code":
                    return base_dial_code;
            }
        },
        calculateLightness: (color) => {
            let r = parseInt(color.substr(1, 2), 16);
            let g = parseInt(color.substr(3, 2), 16);
            let b = parseInt(color.substr(5, 2), 16);
            return (Math.max(r, g, b) + Math.min(r, g, b)) / 510;
        },
        calculateDarkness: (color) => {
            let lightness = this.renders.calculateLightness(color);
            return 1 - lightness;
        },
        isLightColor: (color, treshold = 0.65) => {
            let lightnessThreshold = treshold;
            let lightness = this.renders.calculateLightness(color);
            return lightness > lightnessThreshold;
        },
        isDarkColor: (color, threshold = 0.65) => {
            let darkness = this.renders.calculateDarkness(color);
            return darkness > threshold;
        },
        getColorRecommendation: (type) => {

            let treshold = 0.65;
            let color = "color";

            if (type === "color") {
                treshold = 0.65;
            } else if (type === "qr") {
                treshold = 0.3;
                color = "qrColor"
            } else {
                treshold = 0.65;
            }

            try {

                // Check if the selected theme is "proptree"
                if (this.state.form && this.state.form.theme && this.state.form.theme.value === 'proptree') {
                    return ""; // Return empty string for "proptree" theme
                }

                let { form } = this.state;
                let { themeIslandStyle, themeIslandBackground, themeBackgroundFilter } = form;

                // Check theme settings
                let islandStyleDark = themeIslandStyle.value === "dark";
                let islandBackgroundDisabled = themeIslandBackground.value === "disabled";
                let backgroundFilterDark = themeBackgroundFilter.value === "dark";

                // Determine if color is dark or light based on conditions
                if ((islandStyleDark || (islandBackgroundDisabled && backgroundFilterDark))) {
                    return this.renders.isDarkColor("#" + form[color], treshold) ? "(go lighter to avoid unexpected results)" : "";
                } else {
                    return this.renders.isLightColor("#" + form[color], treshold) ? "(go darker to avoid unexpected results)" : "";
                }

            } catch (error) {

            }

        },
        starsValidation: () => {
            let stars_exist = false;
            let all_questions_has_stars = true;
            if (Array.isArray(this.state.form.questions)) {
                this.state.form.questions.map((question) => {
                    if (Array.isArray(question.options)) {
                        let has_stars = false;
                        question.options.map((item) => {
                            if (item.correct_option) {
                                stars_exist = true;
                                has_stars = true;
                            }
                        });
                        if (!has_stars) {
                            all_questions_has_stars = false;
                        }
                    }
                });
            }
            return !stars_exist || (stars_exist && all_questions_has_stars);
        }
    };

    calls = {
        createForm: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/form";
            return apiRegister.call(options, url);
        },
        updateForm: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/form/" + this.state.form.id;
            return apiRegister.call(options, url);
        },
        getListings: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let url = apiRegister.url.api + "/listings";
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-60" onClose={() => {

                }}>
                    <div className="absolute inset-0 overflow-hidden">

                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-50" />

                        <div className="fixed inset-y-0 max-w-full right-0 flex p-5">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={(this.state.form.id ? "max-w-7xl" : "max-w-4xl") + " w-screen max-w-7xl rounded-lg overflow-hidden"}>
                                    <div className="h-full flex flex-row bg-white shadow-xl">

                                        {
                                            this.state.form.id &&
                                            <div className="md:flex hidden flex-1 flex-col">
                                                <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                    <div className="flex items-center justify-between">
                                                        <Dialog.Title className="text-xl font-bold text-gray-700">Preview</Dialog.Title>
                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: "34px", zIndex: "99" }} className="py-5 pb-2 grid grid-cols-12 bg-white gap-4 px-4 sm:px-6">
                                                    <div className="col-span-12 sm:col-span-12 mb-4 md:lg-0">
                                                        <div className="space-y-6">
                                                            <div>
                                                                <label htmlFor="feed-channel" className="block text-sm font-medium text-gray-900">
                                                                    Listing
                                                                </label>
                                                                <div className="mt-1">
                                                                    <SingleSelection
                                                                        select={async (option) => {
                                                                            this.state.listing = option;
                                                                            await this.promisedSetState({
                                                                                listing: this.state.listing
                                                                            });
                                                                        }}
                                                                        loader={this.state.loading_listings}
                                                                        selected={this.state.listing ? this.state.listing : { id: 0, name: 'Click to Select' }}
                                                                        options={this.state.listings}
                                                                        name="feed-channel"
                                                                        id="feed-channel"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-center mt-12 px-4 sm:px-6">
                                                    <div
                                                        className="bg-gray-100 relative border overflow-hidden rounded-lg"
                                                        style={{ width: "450px", height: "450px", boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center" }}
                                                    >
                                                        {!this.state.listing && (
                                                            <div className="w-full h-full text-sm flex items-center justify-center text-gray-700">
                                                                Select listing to see preview ...
                                                            </div>
                                                        )}
                                                        {!this.state.hide_preview && this.state.listing && (
                                                            <div className="w-full h-full relative">
                                                                <iframe
                                                                    id="form_preview"
                                                                    src={"https://app.adbooster.ai/form/" + this.state.form.id + "?utm_listing=" + this.state.listing.reference + "&preview=true"}
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        border: "none",
                                                                        objectFit: "contain",
                                                                    }}
                                                                    frameBorder="0"
                                                                    scrolling="no"
                                                                    allowFullScreen
                                                                    aria-hidden="false"
                                                                    tabIndex="0"
                                                                ></iframe>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="max-w-4xl w-full h-full flex flex-col border-l">
                                            <div className="py-5 pb-2 bg-white px-4 sm:px-6">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="text-xl font-bold text-gray-700">{this.state.form.id ? "Update Form" : "New Form"}</Dialog.Title>
                                                    <div className="ml-3 h-10 flex items-center">
                                                        <button
                                                            type="button"
                                                            className="rounded-full h-12 w-12 flex items-center justify-center text-red-500 bg-red-100 hover:text-red-600 focus:outline-none focus:ring-2 focus:ring-red"
                                                            onClick={() => {
                                                                this.props.toggle();
                                                            }}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="form-container" className="flex-1 h-0 overflow-y-auto">

                                                <nav className="flex px-6 mt-4" aria-label="Tabs">
                                                    {[{ name: "Qualification", value: "questions" }, { name: "Styling", value: "styling" }].map((tab) => (
                                                        <div
                                                            key={tab.name}
                                                            className={classNames(
                                                                this.state.tab.value == tab.value ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                                                                'rounded-md px-3 py-2 text-sm font-medium cursor-pointer mr-4'
                                                            )}
                                                            onClick={() => {
                                                                this.setState({
                                                                    tab: tab
                                                                })
                                                            }}
                                                            aria-current={tab.current ? 'page' : undefined}
                                                        >
                                                            {tab.name}
                                                        </div>
                                                    ))}
                                                </nav>

                                                {
                                                    this.state.tab.value == "questions" &&
                                                    <div className="flex flex-row">
                                                        <div className="flex flex-1 flex-col pt-4">
                                                            <div className="px-4 grid-cols-12 grid gap-4 sm:px-6">
                                                                <div className="col-span-12 sm:col-span-6 divide-y divide-gray-200">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="form-name" className="block text-sm font-medium text-gray-900">
                                                                                Name
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <input
                                                                                    onChange={(event) => {
                                                                                        this.state.form.name = event.target.value;
                                                                                        this.setState({
                                                                                            form: this.state.form
                                                                                        })
                                                                                    }}
                                                                                    disabled={this.state.form.id}
                                                                                    value={this.state.form.name ? this.state.form.name : ""}
                                                                                    type="text"
                                                                                    name="form-name"
                                                                                    id="form-name"
                                                                                    className={"appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" + (this.state.form.id ? " bg-gray-100 cursor-not-allowed" : "")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="form-lang" className="block text-sm font-medium text-gray-900">
                                                                                Language
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                {
                                                                                    //new with a-z filter and base_lang on top
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.state.form.language = option;
                                                                                            this.setState({
                                                                                                form: this.state.form
                                                                                            })
                                                                                        }}
                                                                                        disabled={this.state.form.id}
                                                                                        selected={this.state.form.language && this.state.form.language.value ? this.state.form.language : this.state.lang_options.find(option => option.value === this.state.base_language) || this.state.lang_options[0]}
                                                                                        options={[
                                                                                            { id: 1, name: 'English', value: "en" },
                                                                                            { id: 2, name: 'Swedish', value: "sv" },
                                                                                            { id: 3, name: 'Arabic', value: "ar" },
                                                                                            { id: 4, name: 'German', value: "de" },
                                                                                            { id: 5, name: 'Spanish', value: "es" },
                                                                                            { id: 6, name: 'French', value: "fr" },
                                                                                            { id: 7, name: 'Russian', value: "ru" },
                                                                                            { id: 8, name: 'Dutch', value: "nl" },
                                                                                            { id: 9, name: 'Portuguese', value: "pt" },
                                                                                            { id: 10, name: 'Italian', value: "it" }
                                                                                        ].sort((a, b) => {
                                                                                            if (a.value === this.state.base_language) return -1;
                                                                                            if (b.value === this.state.base_language) return 1;
                                                                                            return a.name.localeCompare(b.name);
                                                                                        })}
                                                                                        name="form-lang"
                                                                                        id="form-lang"
                                                                                    />
                                                                                }
                                                                                {
                                                                                    //old
                                                                                    /*
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.state.form.language = option;
                                                                                            this.setState({
                                                                                                form: this.state.form
                                                                                            })
                                                                                        }}
                                                                                        disabled={this.state.form.id}
                                                                                        selected={this.state.form.language && this.state.form.language.value ? this.state.form.language : { id: 1, name: 'English', value: "en" }}
                                                                                        options={[
                                                                                            { id: 1, name: 'English', value: "en" },
                                                                                            { id: 2, name: 'Swedish', value: "sv" },
                                                                                            { id: 3, name: 'Arabic', value: "ar" },
                                                                                            { id: 4, name: 'German', value: "de" },
                                                                                            { id: 5, name: 'Spanish', value: "es" },
                                                                                            { id: 6, name: 'French', value: "fr" },
                                                                                            { id: 7, name: 'Russian', value: "ru" },
                                                                                            { id: 8, name: 'Dutch', value: "nl" },
                                                                                            { id: 9, name: 'Portuguese', value: "pt" },
                                                                                            { id: 10, name: 'Italian', value: "it" }
                                                                                        ]}
                                                                                        name="form-lang"
                                                                                        id="form-lang"
                                                                                    />
                                                                                    */
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="form-questions" className="block text-sm font-medium text-gray-900">
                                                                                Questions
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.form.version = option;
                                                                                        if (option.value == "no_questions") {
                                                                                            this.state.form.type = { id: 1, name: '2FA -> Qualify', value: 'questions_last' };
                                                                                        }
                                                                                        this.setState({
                                                                                            form: this.state.form
                                                                                        });
                                                                                    }}
                                                                                    disabled={(this.state.user_enterprise && this.state.user_enterprise.managerUIcreateQuestions && this.state.user_enterprise.managerUIcreateQuestions.value == "disabled") || (this.state.form.id)}
                                                                                    selected={this.state.form.version && this.state.form.version.value ? this.state.form.version : {}}
                                                                                    options={[
                                                                                        { id: 1, name: 'Enabled', value: 'questions' },
                                                                                        { id: 2, name: 'Disabled', value: 'no_questions' },
                                                                                        //{ id: 3, name: 'Third party questions', value: 'third_part' }
                                                                                    ]}
                                                                                    name="form-questions"
                                                                                    id="form-questions"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="form-order" className="block text-sm font-medium text-gray-900">
                                                                                Order
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.form.type = option;
                                                                                        this.setState({
                                                                                            form: this.state.form
                                                                                        })
                                                                                    }}
                                                                                    disabled={(this.state.form.version && this.state.form.version.value == "no_questions") || (this.state.form.id)}
                                                                                    selected={this.state.form.type && this.state.form.type.value ? this.state.form.type : {}}
                                                                                    options={[
                                                                                        { id: 1, name: '2FA -> Qualify', value: 'questions_last' },
                                                                                        { id: 2, name: 'Qualify -> 2FA', value: 'questions_first' }
                                                                                    ]}
                                                                                    name="form-order"
                                                                                    id="form-order"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-6 relative">
                                                                    <div className="space-y-6 pb-5">
                                                                        <div>
                                                                            <label htmlFor="form-perks" className="block text-sm font-medium text-gray-900">
                                                                                Perk mode
                                                                                {
                                                                                    this.state.form.perks && this.state.form.perks.value && this.state.form.perks.value === "perks_enabled" &&
                                                                                    <span className="ml-1 text-xs text-gray-500">(active on listings with PDF, 360 tour or floor plan)</span>
                                                                                }
                                                                            </label>
                                                                            <div className="mt-1">
                                                                                <SingleSelection
                                                                                    select={(option) => {
                                                                                        this.state.form.perks = option;
                                                                                        this.setState({
                                                                                            form: this.state.form
                                                                                        });
                                                                                    }}
                                                                                    disabled={(this.state.user_enterprise && this.state.user_enterprise.managerUIcreateQuestions && this.state.user_enterprise.managerUIcreateQuestions.value == "disabled") || (this.state.form.version && this.state.form.version.value == "no_questions") || (this.state.form.id)}
                                                                                    selected={this.state.form.perks && this.state.form.perks.value ? this.state.form.perks : { id: 2, name: 'Disabled', value: 'perks_disabled' }}
                                                                                    options={[
                                                                                        { id: 1, name: 'Enabled', value: 'perks_enabled' },
                                                                                        { id: 2, name: 'Disabled', value: 'perks_disabled' },
                                                                                    ]}
                                                                                    name="form-perks"
                                                                                    id="form-perks"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="px-4 grid-cols-12 grid gap-4 sm:px-6">
                                                                {
                                                                    //this.state.form.type && this.state.form.type.value == 'questions_last' &&
                                                                    <div className="col-span-12 -mb-2 text-xs">
                                                                        Step one
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.form.type && this.state.form.type.value == 'questions_last' &&
                                                                    <div className="col-span-12">
                                                                        <div className="font-medium text-gray-900 items-center rounded-md flex bg-gray-50 p-4 border overflow-hidden">
                                                                            <div className="mr-5">
                                                                                <QrcodeIcon className="w-5" />
                                                                            </div>
                                                                            <div className="flex flex-1 justify-start">2FA client with WhatsApp</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.form.type && this.state.form.type.value == 'questions_last' &&
                                                                    <div className="col-span-12 -mb-2 text-xs">
                                                                        Step two
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.form.version && this.state.form.version.value == 'no_questions' &&
                                                                    <div className="col-span-12">
                                                                        <div className="font-medium text-gray-900 items-center rounded-md flex bg-gray-50 p-4 border overflow-hidden">
                                                                            <div className="mr-5">
                                                                                <BadgeCheckIcon className="w-5" />
                                                                            </div>
                                                                            <div className="flex flex-1 justify-start">Confirmation</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    !(this.state.form.version && this.state.form.version.value == 'no_questions') &&
                                                                    <div className="col-span-12 grid grid-cols-12 gap-4">
                                                                        {
                                                                            Array.isArray(this.state.form.questions) &&
                                                                            this.state.form.questions.map((item, index) => {
                                                                                return (
                                                                                    <div className={"rounded-md bg-gray-50 col-span-12 border overflow-hidden"}>
                                                                                        <div className="bg-gray-50 p-4 grid grid-cols-12">
                                                                                            <div className="col-span-12 font-medium text-gray-900 items-center justify-center flex flex-row">
                                                                                                <div className="flex flex-1 flex-row items-center">
                                                                                                    <div className="mr-5">
                                                                                                        <TrashIcon onClick={() => {
                                                                                                            if (this.state.form.questions.length > 1) {
                                                                                                                this.state.form.questions = this.state.form.questions.filter((inner_item) => {
                                                                                                                    return inner_item.index.value !== item.index.value;
                                                                                                                });
                                                                                                                this.setState({
                                                                                                                    form: this.state.form
                                                                                                                });
                                                                                                            }
                                                                                                        }} className={(this.state.form.questions.length > 1 ? "cursor-pointer text-red-500 hover:text-red-700" : "cursor-not-allowed text-gray-600") + " w-5 "} />
                                                                                                    </div>
                                                                                                    {
                                                                                                        item.question && item.question !== "" &&
                                                                                                        <span>{item.question}</span>
                                                                                                        ||
                                                                                                        <span>...</span>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="ml-3">
                                                                                                    <div className={"text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700"} onClick={() => {
                                                                                                        item.open = !item.open;
                                                                                                        this.setState({
                                                                                                            form: this.state.form
                                                                                                        })
                                                                                                    }}>
                                                                                                        {item.open ? "Close" : "Open"}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className={(item.open ? "mt-4" : "h-0") + " grid col-span-12 overflow-hidden transition ease-in-out duration-200 grid-cols-12 gap-4"}>
                                                                                                <div className="col-span-6">
                                                                                                    <label htmlFor="form-question" className="block text-sm font-medium text-gray-900">
                                                                                                        Question
                                                                                                    </label>
                                                                                                    <div className="mt-1">
                                                                                                        <input
                                                                                                            onChange={(event) => {
                                                                                                                item.question = event.target.value;
                                                                                                                this.setState({
                                                                                                                    form: this.state.form
                                                                                                                })
                                                                                                            }}
                                                                                                            value={item.question ? item.question : ""}
                                                                                                            type="text"
                                                                                                            name="form-question"
                                                                                                            id="form-question"
                                                                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-span-6">
                                                                                                    <label htmlFor="form-position" className="block text-sm font-medium text-gray-900">
                                                                                                        Position
                                                                                                    </label>
                                                                                                    <div className="mt-1">
                                                                                                        <SingleSelection
                                                                                                            select={(option) => {
                                                                                                                item.index = option;
                                                                                                                this.setState({
                                                                                                                    form: this.state.form
                                                                                                                })
                                                                                                            }}
                                                                                                            selected={item.index ? item.index : { id: 0, name: 'Click to Select' }}
                                                                                                            options={Object.keys(this.state.questionsIndex).filter((item) => {
                                                                                                                return this.state.questionsIndex[item].value < this.state.form.questions.length;
                                                                                                            }).map((item) => {
                                                                                                                return this.state.questionsIndex[item];
                                                                                                            })}
                                                                                                            name="form-position"
                                                                                                            id="form-position"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    <div className="col-span-12">
                                                                                                        <label htmlFor="form-lang" className="block text-sm font-medium text-gray-900">
                                                                                                            {this.state.form.language && this.state.form.language.value === "en" ?
                                                                                                                "Description" : "English translation"}
                                                                                                            <span className="ml-1 text-xs">
                                                                                                                {this.state.form.language && this.state.form.language.value === "en" ?
                                                                                                                    "(optional)" : "(recommended)"}
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <div className="mt-1">
                                                                                                            <input
                                                                                                                onChange={(event) => {
                                                                                                                    item.information = event.target.value;
                                                                                                                    this.setState({
                                                                                                                        form: this.state.form
                                                                                                                    })
                                                                                                                }}
                                                                                                                value={item.information ? item.information : ""}
                                                                                                                type="text"
                                                                                                                name="form-lang"
                                                                                                                id="form-lang"
                                                                                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    Array.isArray(item.options) &&
                                                                                                    item.options.map((option, index) => {
                                                                                                        return (
                                                                                                            <div className="col-span-12">
                                                                                                                <label htmlFor="form-title" className="block text-sm font-medium text-gray-900">
                                                                                                                    Answer {index + 1}<span className="ml-1 text-xs">{this.state.form.language && this.state.form.language.value === "en" ? "" : "(English translation in brackets recommended)"}</span>
                                                                                                                </label>
                                                                                                                <div className="mt-1 flex flex-row items-center justify-center">
                                                                                                                    <div className="flex-1 flex">
                                                                                                                        <input
                                                                                                                            onChange={(event) => {
                                                                                                                                option.title = event.target.value;
                                                                                                                                this.setState({
                                                                                                                                    form: this.state.form
                                                                                                                                })
                                                                                                                            }}
                                                                                                                            value={option.title ? option.title : ""}
                                                                                                                            type="text"
                                                                                                                            name="form-title"
                                                                                                                            id="form-title"
                                                                                                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div onClick={() => {
                                                                                                                        option.correct_option = !option.correct_option;
                                                                                                                        this.setState({
                                                                                                                            form: this.state.form
                                                                                                                        });
                                                                                                                    }} className={(option.correct_option ? "  text-white bg-purple-500" : "text-gray-800 hover:text-purple-500") + "  ml-3 bg-white border cursor-pointer rounded-md h-10 w-16 flex justify-center"}>
                                                                                                                        <StarIcon className={"w-5"} />
                                                                                                                    </div>
                                                                                                                    <div onClick={() => {
                                                                                                                        if (item.options.length > 1) {
                                                                                                                            item.options = item.options.filter((inner_item) => {
                                                                                                                                return inner_item.id !== option.id;
                                                                                                                            });
                                                                                                                            this.setState({
                                                                                                                                form: this.state.form
                                                                                                                            });
                                                                                                                        }
                                                                                                                    }} className={(item.options.length > 1 ? "bg-white hover:text-red-700 cursor-pointer" : "cursor-not-allowed text-gray-600") + " ml-3 border  rounded-md h-10 w-16  flex justify-center"}>
                                                                                                                        <TrashIcon className={"w-5"} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    item.options.length < 6 &&
                                                                                                    <div className="col-span-12">
                                                                                                        <div className="max-h-6 text-sm font-medium flex flex-row flex-no-wrap text-purple-500 cursor-pointer">
                                                                                                            <div onClick={() => {
                                                                                                                item.options.push({ id: Math.floor(Math.random() * 10000000000000), title: "", qualification: false });
                                                                                                                this.setState({
                                                                                                                    form: this.state.form
                                                                                                                })
                                                                                                            }} className="flex flex-row flex-no-wrap hover:text-purple-700">
                                                                                                                <PlusIcon className="w-5" />
                                                                                                                <span className="ml-2">Add answer</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.form.type && this.state.form.type.value == 'questions_first' &&
                                                                    <div className="col-span-12 -mb-2 text-xs">
                                                                        Step two
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.form.type && this.state.form.type.value == 'questions_first' &&
                                                                    <div className="col-span-12">
                                                                        <div className="font-medium text-gray-900 items-center rounded-md flex bg-gray-50 p-4 border overflow-hidden">
                                                                            <div className="mr-5">
                                                                                <QrcodeIcon className="w-5" />
                                                                            </div>
                                                                            <div className="flex flex-1 justify-start">2FA client with WhatsApp</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    !(this.state.form.version && this.state.form.version.value == 'no_questions') &&
                                                                    <div className="col-span-12 -mb-2 text-xs">
                                                                        Step three
                                                                    </div>
                                                                }
                                                                {
                                                                    !(this.state.form.version && this.state.form.version.value == 'no_questions') &&
                                                                    <div className="col-span-12">
                                                                        <div className="font-medium text-gray-900 items-center rounded-md flex bg-gray-50 p-4 border overflow-hidden">
                                                                            <div className="mr-5">
                                                                                <BadgeCheckIcon className="w-5" />
                                                                            </div>
                                                                            <div className="flex flex-1 justify-start">Confirmation</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.tab.value == "styling" &&
                                                    <div className="flex flex-row">
                                                        <div className="flex flex-1 flex-col pt-4">
                                                            <div className="px-4 grid-cols-12 grid gap-4 sm:px-6">
                                                                <div className="col-span-12 sm:col-span-6 divide-y divide-gray-200">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="default_color" className="block text-sm font-medium text-gray-900">
                                                                                {this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree" ? "Background color" : "Theme color"}
                                                                                <span className="ml-1 text-xs text-orange-500">
                                                                                    {this.renders.getColorRecommendation("color")}
                                                                                </span>
                                                                            </label>
                                                                            <div style={{ height: "38.5px" }} className="mt-1 flex overflow-hidden border border-gray-300 rounded-md shadow-sm flex-row">
                                                                                <div className={"h-full px-2 flex justify-center items-center bg-gray-50 border-r"}>#</div>
                                                                                <div className={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree" ? "bg-gray-100 flex items-center flex-1 p-1" : "flex items-center flex-1 p-1"}>
                                                                                    <input
                                                                                        className={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree" ? "cursor-not-allowed w-full border-none p-2 focus:outline-none sm:text-sm bg-transparent" : "w-full border-none p-2 focus:outline-none sm:text-sm bg-transparent"}
                                                                                        type="text"
                                                                                        placeholder=''
                                                                                        value={this.state.form.color}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        onClick={(e) => {
                                                                                            //this.setState({ selectedId: e.target.id })
                                                                                        }}
                                                                                        onChange={async (e) => {
                                                                                            e.target.value = e.target.value.replace(/#/g, "");
                                                                                            this.state.form.color = e.target.value;
                                                                                            this.setState({
                                                                                                form: this.state.form
                                                                                            });
                                                                                        }}
                                                                                        name="form-bg-color"
                                                                                        id="default_color"
                                                                                    />
                                                                                </div>
                                                                                <div className="relative overflow-hidden w-16">
                                                                                    <input
                                                                                        style={{ height: "100%", width: "100%" }}
                                                                                        className={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree" ? "absolute cursor-not-allowed bg-gray-100 p-1" : "absolute cursor-pointer bg-white p-1"}
                                                                                        type="color"
                                                                                        value={"#" + this.state.form.color}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        onChange={async (e) => {
                                                                                            let color_string = e.target.value.slice(1);
                                                                                            this.state.form.color = color_string;
                                                                                            this.setState({
                                                                                                form: this.state.form
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-12 sm:col-span-6 divide-y divide-gray-200">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <label htmlFor="default_color" className="block text-sm font-medium text-gray-900">
                                                                                {this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree" ? "Accent color" : "QR code color"}
                                                                                <span className="ml-1 text-xs text-orange-500">
                                                                                    {this.renders.getColorRecommendation("qr")}
                                                                                </span>
                                                                            </label>
                                                                            <div style={{ height: "38.5px" }} className="mt-1 flex overflow-hidden border border-gray-300 rounded-md shadow-sm flex-row">
                                                                                <div className={"h-full px-2 flex justify-center items-center bg-gray-50 border-r"}>#</div>
                                                                                <div className="flex items-center flex-1 p-1">
                                                                                    <input
                                                                                        className="w-full border-none p-2 focus:outline-none sm:text-sm bg-transparent"
                                                                                        type="text"
                                                                                        placeholder=''
                                                                                        value={this.state.form.qrColor}
                                                                                        onClick={(e) => {
                                                                                            //this.setState({ selectedId: e.target.id })
                                                                                        }}
                                                                                        onChange={async (e) => {
                                                                                            e.target.value = e.target.value.replace(/#/g, "");
                                                                                            this.state.form.qrColor = e.target.value;
                                                                                            this.setState({
                                                                                                form: this.state.form
                                                                                            });
                                                                                        }}
                                                                                        name="form-bg-color"
                                                                                        id="default_color"
                                                                                    />
                                                                                </div>
                                                                                <div className="relative overflow-hidden w-16">
                                                                                    <input
                                                                                        style={{ height: "100%", width: "100%" }}
                                                                                        className="absolute cursor-pointer bg-white p-1"
                                                                                        type="color"
                                                                                        value={"#" + this.state.form.qrColor}
                                                                                        onChange={async (e) => {
                                                                                            let color_string = e.target.value.slice(1);
                                                                                            this.state.form.qrColor = color_string;
                                                                                            this.setState({
                                                                                                form: this.state.form
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    <div className="col-span-12 sm:col-span-12 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Theme
                                                                                    <span className="ml-1 text-xs text-orange-500">
                                                                                        {/* this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree" ? "(locked)" : "" */}
                                                                                    </span>
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.functions.setThemeSettings(option);
                                                                                        }}
                                                                                        selected={this.state.form.theme && this.state.form.theme.value ? this.state.form.theme : { id: 1, name: 'Platform', value: "platform" }}
                                                                                        options={[
                                                                                            { id: 1, name: 'Platform', value: "platform" },
                                                                                            { id: 2, name: 'Dark mode', value: "dark_mode" },
                                                                                            { id: 3, name: 'B/w mode', value: "bw_mode" },
                                                                                            { id: 4, name: 'Proptree', value: "proptree" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Background effect
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.setState((prevState) => ({
                                                                                                form: {
                                                                                                    ...prevState.form,
                                                                                                    themeBackgroundEffect: option
                                                                                                }
                                                                                            }));
                                                                                        }}
                                                                                        selected={this.state.form.themeBackgroundEffect && this.state.form.themeBackgroundEffect.value ? this.state.form.themeBackgroundEffect : { id: 3, name: 'Mesh', value: "mesh" }}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        options={[
                                                                                            { id: 1, name: 'Solid', value: "solid" },
                                                                                            { id: 2, name: 'Gradient', value: "gradient" },
                                                                                            { id: 3, name: 'Mesh', value: "mesh" },
                                                                                            { id: 4, name: 'Blur', value: "blur" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Background filter
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.setState((prevState) => ({
                                                                                                form: {
                                                                                                    ...prevState.form,
                                                                                                    themeBackgroundFilter: option
                                                                                                }
                                                                                            }));
                                                                                        }}
                                                                                        selected={this.state.form.themeBackgroundFilter && this.state.form.themeBackgroundFilter.value ? this.state.form.themeBackgroundFilter : { id: 1, name: 'None', value: "none" }}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        options={[
                                                                                            { id: 1, name: 'None', value: "none" },
                                                                                            { id: 2, name: 'Color', value: "color" },
                                                                                            { id: 3, name: 'Dark', value: "dark" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Photo filter
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.setState((prevState) => ({
                                                                                                form: {
                                                                                                    ...prevState.form,
                                                                                                    themePhotoFilter: option
                                                                                                }
                                                                                            }));
                                                                                        }}
                                                                                        selected={this.state.form.themePhotoFilter && this.state.form.themePhotoFilter.value ? this.state.form.themePhotoFilter : { id: 1, name: 'Color', value: "color" }}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        options={[
                                                                                            { id: 1, name: 'Color', value: "color" },
                                                                                            { id: 2, name: 'B/w', value: "bw" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.form && this.state.form.themeIslandBackground && this.state.form.themeIslandBackground.value !== "disabled" &&
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Island style
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.setState((prevState) => ({
                                                                                                form: {
                                                                                                    ...prevState.form,
                                                                                                    themeIslandStyle: option
                                                                                                }
                                                                                            }));
                                                                                        }}
                                                                                        selected={this.state.form.themeIslandStyle && this.state.form.themeIslandStyle.value ? this.state.form.themeIslandStyle : { id: 1, name: 'Bright', value: "bright" }}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        options={[
                                                                                            { id: 1, name: 'Bright', value: "bright" },
                                                                                            { id: 2, name: 'Dark', value: "dark" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Island background
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.setState((prevState) => ({
                                                                                                form: {
                                                                                                    ...prevState.form,
                                                                                                    themeIslandBackground: option
                                                                                                }
                                                                                            }));
                                                                                        }}
                                                                                        selected={this.state.form.themeIslandBackground && this.state.form.themeIslandBackground.value ? this.state.form.themeIslandBackground : { id: 1, name: 'Enabled', value: "enabled" }}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        options={[
                                                                                            { id: 1, name: 'Enabled', value: "enabled" },
                                                                                            { id: 2, name: 'Disabled', value: "disabled" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="col-span-12 sm:col-span-6 relative">
                                                                        <div className="space-y-6">
                                                                            <div>
                                                                                <label htmlFor="form-setting" className="block text-sm font-medium text-gray-900">
                                                                                    Accent
                                                                                </label>
                                                                                <div className="mt-1">
                                                                                    <SingleSelection
                                                                                        select={(option) => {
                                                                                            this.setState((prevState) => ({
                                                                                                form: {
                                                                                                    ...prevState.form,
                                                                                                    themeAccent: option
                                                                                                }
                                                                                            }));
                                                                                        }}
                                                                                        selected={this.state.form.themeAccent && this.state.form.themeAccent.value ? this.state.form.themeAccent : { id: 1, name: 'Partial', value: "partial" }}
                                                                                        disabled={this.state.form && this.state.form.theme && this.state.form.theme.value === "proptree"}
                                                                                        options={[
                                                                                            { id: 1, name: 'Partial', value: "partial" },
                                                                                            { id: 2, name: 'Full', value: "full" }
                                                                                        ]}
                                                                                        name="form-setting"
                                                                                        id="form-setting"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.stars_hover && !this.renders.starsValidation() &&
                                                    <div id="scroll-target" className={this.state.error ? "sm:px-6 px-4" : "sm:px-6 px-4 pb-6"}>
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4 mt-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <ExclamationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-orange-800">There were some warnings</h3>
                                                                    <div className="mt-2 text-sm text-orange-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{"Star all your qualifying answers"}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.error &&
                                                    <div className="sm:px-6 px-4 pb-6">
                                                        <div className="rounded-md bg-red-100 bg-opacity-50 p-4 mt-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                                </div>
                                                                <div className="ml-3">
                                                                    <h3 className="text-sm font-medium text-red-800">There were some errors</h3>
                                                                    <div className="mt-2 text-sm text-red-700">
                                                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                                                            <li>{this.state.error}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="flex-shrink-0 px-6 py-4 flex flex-row">
                                                <div className="flex flex-1 justify-start">
                                                    {
                                                        !(this.state.form.version && this.state.form.version.value == 'no_questions') &&
                                                        this.state.tab.value == "questions" &&
                                                        <div
                                                            onClick={() => {
                                                                if (this.state.form.questions.length < 10) {
                                                                    let index = this.state.questionsIndex[this.state.form.questions.length + 1];
                                                                    this.state.form.questions = this.state.form.questions.map((item) => {
                                                                        item.open = false;
                                                                        return item;
                                                                    });
                                                                    this.state.form.questions.push({
                                                                        index: index,
                                                                        open: true,
                                                                        id: Math.floor(Math.random() * 10000000000000),
                                                                        question: "",
                                                                        information: "",
                                                                        options: [{ id: Math.floor(Math.random() * 10000000000000), title: "", qualification: false }]
                                                                    });
                                                                    this.setState({
                                                                        form: this.state.form
                                                                    });
                                                                }
                                                            }}
                                                            className={(Array.isArray(this.state.form.questions) && this.state.form.questions.length < 10 ? " cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " inline-flex text-center relative justify-center items-center py-2 px-10 lg:px-20 border text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                        >
                                                            Add question
                                                        </div>
                                                    }
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (this.renders.starsValidation() && ((this.state.form.version && this.state.form.version.value == "no_questions") || (Array.isArray(this.state.form.questions) && this.state.form.questions.length > 1))) {
                                                            if (this.state.form.id) {
                                                                this.functions.updateForm();
                                                            } else {
                                                                this.functions.createForm();
                                                            }
                                                        }
                                                    }}
                                                    onMouseEnter={() => {
                                                        if (!this.renders.starsValidation() && Array.isArray(this.state.form.questions) && this.state.form.questions.length > 1) {
                                                            this.setState({
                                                                stars_hover: true
                                                            });
                                                        }
                                                    }}
                                                    onTouchStart={() => {
                                                        if (!this.renders.starsValidation() && Array.isArray(this.state.form.questions) && this.state.form.questions.length > 1) {
                                                            this.setState({
                                                                stars_hover: true
                                                            });
                                                        }
                                                    }}
                                                    className={(this.renders.starsValidation() && this.state.form.name !== "" && ((this.state.form.version && this.state.form.version.value == "no_questions") || (Array.isArray(this.state.form.questions) && this.state.form.questions.length > 1)) ? "bg-purple-500 hover:bg-purple-600 cursor-pointer" : "bg-gray-200 cursor-not-allowed") + " ml-4 inline-flex text-center relative justify-center items-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                >
                                                    {
                                                        this.state.loading &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.form.id &&
                                                        <span>Create</span>
                                                    }
                                                    {
                                                        this.state.form.id &&
                                                        <span>Update</span>
                                                    }
                                                </div>
                                                {
                                                    this.state.form.id &&
                                                    <div
                                                        onClick={() => {
                                                            this.props.toggle();
                                                        }}
                                                        className={"bg-red-500 hover:bg-red-600 cursor-pointer ml-4 inline-flex text-center relative justify-center items-center py-2 px-10 lg:px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"}
                                                    >
                                                        Close
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        )
    }
}

export default CreateForm;
